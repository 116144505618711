<template>
	<v-card outlined :color="`blue lighten-5`" class="h-100 d-flex flex-column">
		<v-card-title>
			<v-icon class="card-drag-handle">mdi-drag</v-icon>
			<v-icon class="mr-2" color="cyan">mdi-format-list-text</v-icon>
			<div class="my-auto fw-600 text-uppercase fs-18 py-1">Seo Project</div>
			<v-spacer></v-spacer>
			<template v-if="isDurationFilter">
				<v-menu left offset-y :max-width="200" content-class="z-index-11">
					<template v-slot:activator="{ on, attrs }">
						<v-btn height="35" min-height="35" color="cyan lighten-4" depressed v-bind="attrs" v-on="on">
							<span class="fs-16 text-capitalize px-3">{{ dActiveListingProjectDuration.text }}</span>
							<v-icon>mdi-menu-down</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item
							v-for="(item, index) in filterStatusList"
							:key="index"
							@click="filterByDuration(item)"
							:class="[
								'py-1',
								{
									'grey lighten-3': dActiveListingProjectDuration.value == item.value,
								},
							]"
						>
							<v-list-item-title>{{ item.text }}</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</template>
		</v-card-title>
		<v-card-text class="flex-grow-1">
			<div class="">
				<v-slide-group show-arrows>
					<v-slide-item v-for="status in projectTypeList" :key="status.value">
						<v-btn
							:outlined="status.value != dActiveListingProjectStatus"
							:disabled="dLoading"
							class="flex-grow-1 mx-1 white--text"
							height="40"
							min-height="40"
							depressed
							:color="status.color"
							:value="status.value"
							@click="filterByProjectType(status.value)"
						>
							<span style="font-size: 15px">
								{{ status.name }}
								<!-- ({{ status.total }}) -->
							</span>
						</v-btn>
					</v-slide-item>
				</v-slide-group>
			</div>
			<div class="v-data-table bt-table theme--light mt-5">
				<div class="v-data-table__wrapper" style="height: 500px">
					<table id="table" width="100">
						<thead>
							<tr>
								<th class="pa-2">Company</th>
								<th class="pa-2" width="130">Top 3</th>
								<th class="pa-2" width="130">Top 5</th>
								<th class="pa-2" width="130">Top 10</th>
								<th class="pa-2" width="130">Top 20</th>
								<th class="pa-2" width="130">Top 50</th>
								<th class="pa-2" width="130">Top 100</th>
								<th class="pa-2" width="100">Not in SERP</th>
								<th class="pa-2">Keyword</th>
								<th class="pa-2" width="100">Status</th>
							</tr>
						</thead>
						<tbody>
							<!-- dListingProjects -->
							<template v-if="!dLoading">
								<template v-if="dListingProjects.length">
									<tr v-for="(data, index) in dListingProjects" :key="index">
										<td>
											<div class="d-flex align-items-center">
												<div>
													<template v-if="data.company_type">
														<v-img
															v-if="data.company_type == 'genic-solution'"
															width="60"
															height="40"
															:src="$assetURL('media/logos/genic_solution.png')"
															contain
														/>
														<v-img
															v-if="data.company_type == 'bthrust'"
															width="60"
															height="40"
															:src="$assetURL('media/logos/bthurst.png')"
															contain
														/>
													</template>
													<v-img
														v-else
														width="60"
														height="40"
														:src="$assetURL('media/custom-svg/companylogo.svg')"
														contain
													/>
												</div>
												<div class="px-2">
													<p class="fw-500 fs-15">{{ data?.customer?.company_name }}</p>
													<div class="d-flex align-center">
														<span v-if="data.urls" class="fw-500 mb-0 red--text"> data.urls </span>
														<!-- <a :href="data.urls" target="_blank" v-if="data.urls" class="fw-500 mb-0 red--text">{{
															data.urls
														}}</a> -->
														<em class="text-muted" v-else>no web url</em>
													</div>
												</div>
											</div>
										</td>
										<td>
											<div class="d-flex">
												<template v-if="data.ranking_data || data.to_customer">
													<KeywordStatus
														:data="data"
														kpi-key="top_3"
														kpi-six-month-key="kpi_6_month_top_3"
														tooltip-text="To Customer"
														type="customer"
													/>
												</template>
												<div v-else>-</div>
												<template v-if="data.to_delhi || data.ranking_data">
													<KeywordStatus
														:data="data"
														kpi-key="top_3"
														kpi-six-month-key="kpi_6_month_top_3"
														tooltip-text="To Delhi"
														type="delhi"
													/>
												</template>
												<div v-else>-</div>
											</div>
										</td>
										<td>
											<div class="d-flex">
												<template v-if="data.ranking_data || data.to_customer">
													<KeywordStatus
														:data="data"
														kpi-key="top_5"
														kpi-six-month-key="kpi_6_month_top_5"
														tooltip-text="To Delhi"
														type="customer"
													/>
												</template>
												<div v-else>-</div>
												<template v-if="data.to_delhi || data.ranking_data">
													<KeywordStatus
														:data="data"
														kpi-key="top_5"
														kpi-six-month-key="kpi_6_month_top_5"
														tooltip-text="To Delhi"
														type="delhi"
													/>
												</template>
												<div v-else>-</div>
											</div>
										</td>
										<td>
											<div class="d-flex">
												<template v-if="data.ranking_data || data.to_customer">
													<KeywordStatus
														:data="data"
														kpi-key="top_10"
														kpi-six-month-key="kpi_6_month_top_10"
														tooltip-text="To Customer"
														type="customer"
													/>
												</template>
												<div v-else>-</div>
												<template v-if="data.to_delhi || data.ranking_data">
													<KeywordStatus
														:data="data"
														kpi-key="top_10"
														kpi-six-month-key="kpi_6_month_top_10"
														tooltip-text="To Delhi"
														type="delhi"
													/>
												</template>
												<div v-else>-</div>
											</div>
										</td>
										<td>
											<div class="d-flex">
												<template v-if="data.ranking_data || data.to_customer">
													<KeywordStatus
														:data="data"
														kpi-key="top_20"
														kpi-six-month-key="kpi_6_month_top_20"
														tooltip-text="To Customer"
														type="customer"
													/>
												</template>
												<div v-else>-</div>
												<template v-if="data.to_delhi || data.ranking_data">
													<KeywordStatus
														:data="data"
														kpi-key="top_20"
														kpi-six-month-key="kpi_6_month_top_20"
														tooltip-text="To Delhi"
														type="delhi"
													/>
												</template>
												<div v-else>-</div>
											</div>
										</td>
										<td>
											<div class="d-flex">
												<template v-if="data.ranking_data || data.to_customer">
													<KeywordStatus
														:data="data"
														kpi-key="top_50"
														kpi-six-month-key="kpi_6_month_top_50"
														tooltip-text="To Delhi"
														type="customer"
													/>
												</template>
												<div v-else>-</div>
												<template v-if="data.to_delhi || data.ranking_data">
													<KeywordStatus
														:data="data"
														kpi-key="top_50"
														kpi-six-month-key="kpi_6_month_top_50"
														tooltip-text="To Delhi"
														type="delhi"
													/>
												</template>
												<div v-else>-</div>
											</div>
										</td>
										<td>
											<div class="d-flex">
												<template v-if="data.ranking_data || data.to_customer">
													<KeywordStatus
														:data="data"
														kpi-key="top_100"
														kpi-six-month-key="kpi_6_month_top_100"
														tooltip-text="To Customer"
														type="customer"
													/>
												</template>
												<div v-else>-</div>
												<template v-if="data.to_delhi || data.ranking_data">
													<KeywordStatus
														:data="data"
														kpi-key="top_100"
														kpi-six-month-key="kpi_6_month_top_100"
														tooltip-text="To Delhi"
														type="delhi"
													/>
												</template>
												<div v-else>-</div>
											</div>
										</td>
										<td>
											{{
												data.ranking_data && data.ranking_data?.keyword_SERP
													? data?.ranking_data?.keyword_SERP
													: 0
											}}
										</td>
										<td>
											<div class="d-flex">
												<div
													class="brown darken-1 rounded-4 white--text d-flex justify-center align-center"
													style="border-radius: 245px; height: 22px; width: 22px"
												>
													{{ data.total_keyword ? data.total_keyword : 0 }}
												</div>
												<div class="ml-3 font-level-1-bold" style="font-weight: 600">Total</div>
											</div>
											<div class="d-">
												<div
													class="rounded-4 white--text d-flex justify-center align-center font-level-1-bold mt-1 mr-4"
													:class="[idnfColor(data)]"
													style="border-radius: 245px; height: 22px; width: 33px"
												>
													6 M
												</div>
												<div
													class="rounded-4 white--text font-level-1-bold d-flex justify-center align-center mt-1"
													style="border-radius: 245px; height: 22px; width: 33px"
													:class="[idnfColor(data)]"
													v-if="data.month_passed > 6"
												>
													12 M
												</div>
											</div>
											<div class="d-flex mt-1"></div>
										</td>
										<td>
											<v-chip class="mb-1 mr-1" label dark :color="statusColor(data.status)">
												{{ statusText(data.status) }}
											</v-chip>
										</td>
									</tr>
								</template>
								<template v-else>
									<tr class="v-data-table__empty-wrapper">
										<td colspan="10">
											<v-img
												max-width="400"
												class="mx-auto"
												:src="$assetURL('media/bg/no-data-found.png')"
												style="opacity: 0.9"
											></v-img>
										</td>
									</tr>
								</template>
							</template>
							<template v-else>
								<tr class="v-data-table__empty-wrapper" v-for="tr in 5" :key="tr">
									<th class="pa-2" v-for="th in 10" :key="th">
										<v-skeleton-loader class="w-100" boilerplate type="chip"></v-skeleton-loader>
									</th>
								</tr>
							</template>
						</tbody>
					</table>
					<div v-if="dListingProjects.length > 10" class="text-center py-5">
						<v-btn
							:disabled="dLoading"
							height="35"
							min-height="35"
							color="cyan"
							depressed
							@click="goToViewAll"
						>
							<span class="fs-16 text-capitalize px-3">View All</span>
							<v-icon>mdi-arrow-right</v-icon>
						</v-btn>
					</div>
				</div>
			</div>
		</v-card-text>
	</v-card>
</template>
<script>
import { GET_LISTING_PROJECTS, SET_ACTIVE_FILTER } from "@/core/services/store/dashboard.module.js";
import KeywordStatus from "@/view/components/KeywordStatus.vue";
import dashboardMixin from "@/core/mixins/dashboard.listing.mixin.js";
import { mapGetters } from "vuex";
export default {
	name: "AllProjectList",
	mixins: [dashboardMixin],
	data() {
		return {
			pageLoading: false,
			filterStatusList: [
				{ text: "This Week", value: "this_week" },
				{ text: "Next Week", value: "next_week" },
				{ text: "This Month", value: "this_month" },
				{ text: "Next Month", value: "next_month" },
				{ text: "This Quarter", value: "this_quarter" },
				{ text: "Next Quarter", value: "next_quarter" },
				{ text: "This Year", value: "this_year" },
				{ text: "Next Year", value: "next_year" },
			],
			projectType: "Recurring",
			projectTypeList: [
				{
					color: "blue",
					name: "Recurring",
					value: "recurring",
					total: 30,
				},
				{
					color: "red",
					name: "Low Performance",
					value: "low_performance",
					total: 20,
				},
				{
					color: "green",
					name: "High Performance",
					value: "high_performance",
					total: 44,
				},
				{
					color: "orange",
					name: "On Hold",
					value: "on_hold",
					total: 4,
				},
				{
					color: "red",
					name: "Cancelled",
					value: "cancelled",
					total: 12,
				},
			],
			lists: [
				{
					id: 340,
					company: "Business Thrust Pvt Ltd",
					logo: this.$assetURL("media/logos/bthrust.png"),
					urls: "www.bthrust.com",
					cost: 0,
					status: 1,
					status_text: "Active",
					target_google: "Google Singapore",
					ranking_data: null,
					month_passed: 0,
					to_customer: {
						id: 4,
						type: 1,
						top_3: 5,
						top_5: 15,
						seo_id: 340,
						top_10: 65465,
						top_20: 52541,
						top_50: 116,
						top_100: 894,
						project_id: 302,
						kpi_6_month_top_3: 128,
						kpi_6_month_top_5: 1053,
						kpi_6_month_top_10: 8535,
						kpi_6_month_top_20: 65,
						kpi_6_month_top_50: 45,
						kpi_6_month_top_100: 25635,
					},
					to_delhi: {
						id: 5,
						type: 2,
						top_3: 25,
						top_5: 0,
						seo_id: 340,
						top_10: 0,
						top_20: 0,
						top_50: 0,
						top_100: 0,
						project_id: 302,
						kpi_6_month_top_3: 10,
						kpi_6_month_top_5: 0,
						kpi_6_month_top_10: 0,
						kpi_6_month_top_20: 0,
						kpi_6_month_top_50: 0,
						kpi_6_month_top_100: 0,
					},
					team_lead: {
						id: 45,
						role: 8,
						profile_img: null,
						display_name: "team lead(seo)",
					},
				},
			],
		};
	},
	methods: {
		/* filterData(item) {
			if (this.filter != item) {
				this.filter = item;
				this.pageLoading = true;
				setTimeout(() => {
					this.pageLoading = false;
				}, 2000);
			}
		}, */
		goToViewAll() {
			this.$router.push({
				name: "seo",
			});
		},
		filterByDuration(item) {
			if (this.dActiveListingProjectDuration.value != item.value) {
				this.$store.commit(SET_ACTIVE_FILTER, {
					key: "active_listing_project_duration",
					value: item,
				});
				this.getProjects();
			}
		},
		filterByProjectType(item) {
			if (this.dActiveListingProjectStatus != item) {
				this.setDurationList(item);
				this.$store.commit(SET_ACTIVE_FILTER, {
					key: "active_listing_project_status",
					value: item,
				});
				this.getProjects();
			}
		},
		setDurationList(type) {
			this.$store.commit(SET_ACTIVE_FILTER, {
				key: "active_listing_project_duration",
				value: {},
			});
			if (type == "recurring") {
				this.$store.commit(SET_ACTIVE_FILTER, {
					key: "active_listing_project_duration",
					value: {
						text: "This Month",
						value: "this_month",
					},
				});
				this.filterStatusList = [
					{ text: "This Week", value: "this_week" },
					{ text: "Next Week", value: "next_week" },
					{ text: "This Month", value: "this_month" },
					{ text: "Next Month", value: "next_month" },
					{ text: "This Quarter", value: "this_quarter" },
					{ text: "Next Quarter", value: "next_quarter" },
					{ text: "This Year", value: "this_year" },
					{ text: "Next Year", value: "next_year" },
				];
			}
			if (type == "cancelled") {
				this.$store.commit(SET_ACTIVE_FILTER, {
					key: "active_listing_project_duration",
					value: {
						text: "This Month",
						value: "this_month",
					},
				});
				this.filterStatusList = [
					{ text: "This Week", value: "this_week" },
					{ text: "Last Week", value: "last_week" },
					{ text: "This Month", value: "this_month" },
					{ text: "Last Month", value: "last_month" },
					{ text: "This Quarter", value: "this_quarter" },
					{ text: "Last Quarter", value: "last_quarter" },
					{ text: "This Year", value: "this_year" },
					{ text: "Last Year", value: "last_year" },
				];
			}
		},
		getProjects() {
			this.pageLoading = true;
			this.$store
				.dispatch(GET_LISTING_PROJECTS, {
					status: this.dActiveListingProjectStatus,
					filter: this.dActiveListingProjectDuration.value
						? this.dActiveListingProjectDuration.value
						: null,
					user_filter: this.dActiveListingProjectUser.id ? this.dActiveListingProjectUser.id : null,
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	computed: {
		...mapGetters([
			"dLoading",
			"dActiveListingProjectStatus",
			"dActiveListingProjectDuration",
			"dActiveListingProjectUser",
			"dListingProjects",
		]),
		isDurationFilter() {
			switch (this.dActiveListingProjectStatus) {
				case "recurring":
					return true;
				case "cancelled":
					return true;
			}
			return false;
		},
	},
	components: {
		KeywordStatus,
	},
};
</script>
<style lang="scss" scoped>
.v-data-table__wrapper {
	tr:nth-child(even):not(.no-hover) {
		background: #fbf5eb;
	}
	tr {
		td {
			padding: 12px 12px !important;
		}
	}
}
</style>

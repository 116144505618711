var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[(_vm.type == 'customer')?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"x-small":"","label":"","color":"lime darken-4","outlined":""}},'div',attrs,false),on),[_c('span',{class:[
							_vm.data.to_customer && _vm.data.ranking_data
								? _vm.kpiColorIdentifier(
										_vm.data.to_customer[_vm.kpiSixMonthKey],
										_vm.data.to_customer[_vm.kpiKey],
										_vm.data.ranking_data[_vm.kpiKey],
										_vm.data.month_passed
								  )
								: '',
						]},[_vm._v(_vm._s(_vm.data.ranking_data && _vm.data.ranking_data[_vm.kpiKey] > 0 ? _vm.data.ranking_data[_vm.kpiKey] : "-"))]),_c('span',[_vm._v(_vm._s(_vm.data.ranking_data || _vm.data.to_customer ? "/" : ""))]),_c('span',{staticClass:"green--text"},[_vm._v(" "+_vm._s(_vm.data.to_customer ? _vm.data.month_passed > 6 ? _vm.data.to_customer[_vm.kpiKey] > 0 ? _vm.data.to_customer[_vm.kpiKey] : "-" : _vm.data.to_customer[_vm.kpiSixMonthKey] > 0 ? _vm.data.to_customer[_vm.kpiSixMonthKey] : "-" : "-")+" ")])])]}}],null,false,860203995)},[_c('span',[_vm._v(_vm._s(_vm.tooltipText))])])]:_vm._e(),(_vm.type == 'delhi')?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({attrs:{"x-small":"","label":"","color":"lime darken-4","outlined":""}},'v-chip',attrs,false),on),[_c('span',{class:[
							_vm.data.to_customer && _vm.data.ranking_data
								? _vm.kpiColorIdentifier(
										_vm.data.to_customer[_vm.kpiSixMonthKey],
										_vm.data.to_customer[_vm.kpiKey],
										_vm.data.ranking_data[_vm.kpiKey],
										_vm.data.month_passed
								  )
								: '',
						]},[_vm._v(" "+_vm._s(_vm.hasKey(_vm.data, "ranking_data[kpiKey]") && _vm.getValue(_vm.data, "ranking_data[kpiKey]") && _vm.data.ranking_data[_vm.kpiKey] > 0 ? _vm.data.ranking_data[_vm.kpiKey] : "-")+" ")]),_c('span',[_vm._v(_vm._s(_vm.data.ranking_data || _vm.data.to_delhi ? "/" : ""))]),_c('span',{staticClass:"green--text"},[_vm._v(" "+_vm._s(_vm.data.to_delhi ? _vm.data.month_passed > 6 ? _vm.data.to_delhi[_vm.kpiKey] > 0 ? _vm.data.to_delhi[_vm.kpiKey] : "-" : _vm.data.to_delhi[_vm.kpiSixMonthKey] > 0 ? _vm.data.to_delhi[_vm.kpiSixMonthKey] : "-" : "-")+" ")])])]}}],null,false,3039296749)},[_c('span',[_vm._v("To Delhi")])])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }